import faqToggle from 'assets/themes/vacances-campings/js/module/faqToggle'
import dataLayer from 'assets/core/js/page/search/dataLayer'
import imagesSliders from 'assets/themes/vacances-campings/js/page/search/imagesSliders'
import budgetSlider from 'assets/themes/vacances-campings/js/page/search/budgetSlider'
import openProductPageLinks from 'assets/themes/vacances-campings/js/page/search/openProductPageLinks'
import { initModal } from 'assets/core/js/module/paymentFacilities'
import sorting from 'assets/themes/vacances-campings/js/page/search/sorting'
import loading from 'assets/themes/vacances-campings/js/page/search/loading'
import filters from 'assets/themes/vacances-campings/js/page/search/filters'
import recap from 'assets/themes/vacances-campings/js/page/search/recap'
import reminder from 'assets/themes/vacances-campings/js/page/search/reminder'
import map from 'assets/themes/vacances-campings/js/page/search/map'
import productFavorite from 'assets/core/js/page/search/product/favorite'

import 'nouislider/dist/nouislider.min.css'

document.addEventListener('DOMContentLoaded', function () {
  faqToggle()
  dataLayer()
  imagesSliders.init()
  budgetSlider()
  openProductPageLinks()
  initModal()
  sorting()
  loading()
  filters()
  recap()
  reminder()
  map.init()
  productFavorite()
})
