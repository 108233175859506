'use strict'

import common from 'assets/themes/vacances-campings/js/page/common/index'

export default function (): void {
  const resultsEl = document.getElementById('results')

  if (!resultsEl) {
    return
  }

  if (!common.isMobile() && !common.isTablet()) {
    if (document.getElementById('filter_offers_page')) {
      ;(document.getElementById('filter_offers_page') as HTMLInputElement).value = '1'
    }

    document.getElementById('filter_offers_filter')?.click()

    resultsEl.dispatchEvent(new CustomEvent('loading.show'))
  }
}
