'use strict'

import SliderManager from 'assets/core/js/module/sliderManager'

export default function (): void {
  const container = document.getElementById('filter-reminder')

  if (container) {
    const wrapper = container.querySelector('.swiper-wrapper')
    const checked = document.querySelectorAll('#filters-list>fieldset:not([data-filter="top"]) :checked')

    container.setAttribute('data-total', String(checked.length))

    if (checked.length > 0) {
      checked.forEach((input) => {
        const filterEl = input.closest('.filter__block')
        const label = <HTMLElement>input.nextElementSibling
        if (wrapper && label && filterEl) {
          wrapper.innerHTML += `
<div class="swiper-slide filter__reminder" data-filter="${filterEl.getAttribute('data-filter') ?? ''}">
    <span>${label.innerText}</span>
    <a href="#" class="field__reset" data-id="${input.id}"></a>
</div>`
        }
      })

      container.querySelectorAll('.swiper-slide a.field__reset').forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault()
          const id = (<HTMLButtonElement>e.target).dataset.id
          if (id && document.getElementById(id)) {
            ;(<HTMLInputElement>document.getElementById(id)).click()
          }

          if (document.getElementById('filter_offers_page')) {
            ;(<HTMLInputElement>document.getElementById('filter_offers_page')).value = '1'
          }

          if (document.getElementById('filter_offers_filter')) {
            ;(<HTMLInputElement>document.getElementById('filter_offers_filter')).click()
          }
        })
      })

      SliderManager.initSlider('#filter-reminder .swiper', {
        slidesPerView: 'auto',
        spaceBetween: 8,
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
        },
      })
    }
  }
}
