'use strict'

export default function (): void {
  document.querySelectorAll<HTMLElement>('.dca-faq__question').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement) {
      if (this.nextElementSibling?.classList.contains('dca-faq__answer')) {
        this.nextElementSibling?.classList.toggle('dca-faq__answer--opened')

        this.classList.toggle('dca-faq__question--active')
      }
    })
  })
}
