'use strict'

import ElementMover from 'assets/core/js/module/elementMover'
import SidePanel from '@design/objects/sidePanel/twig/assets/index'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'

interface SearchSidePanel {
  activeSidePanel: SidePanel | null
  init: () => void
  closeActiveSidePanel: () => void
  initSearchFiltersSidePanel: () => void
}

export default {
  activeSidePanel: null,

  init: function () {
    this.initSearchFiltersSidePanel()

    return this
  },

  closeActiveSidePanel: function () {
    if (this.activeSidePanel) {
      this.activeSidePanel.close()
    }
  },

  initSearchFiltersSidePanel: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const searchEl = document.getElementById('search-offers')
    const filtersEl = document.getElementById('filter-offers')

    if (!searchEl) {
      return
    }

    const searchSubmitEl = searchEl?.querySelector('[type="submit"]') as HTMLButtonElement
    const filtersSubmitEl = document.getElementById('filter_offers_filter')
    const filtersResetEl = document.getElementById('filter_offers-reset')

    const sidePanelEl = document.getElementById('search-filters-side-panel')

    if (!sidePanelEl) {
      return
    }

    let sidePanel: SidePanel | undefined
    const sidePanelExists = ElementPropertiesManager.hasProperty(sidePanelEl, 'sidePanel')

    if (!sidePanelExists) {
      sidePanel = new SidePanel(sidePanelEl, {
        onOpen: function (sidePanel) {
          self.activeSidePanel = sidePanel

          ElementMover.move(
            'search',
            searchEl,
            sidePanel.getElement()?.querySelector('.side-panel__content .content__block[data-content="search"]') as HTMLElement
          )

          if (filtersEl) {
            filtersEl.dispatchEvent(new CustomEvent('filter.modal.opened'))
            ElementMover.move(
              'filters',
              filtersEl,
              sidePanel.getElement()?.querySelector('.side-panel__content .content__block[data-content="filters"]') as HTMLElement
            )
          }

          ElementMover.move(
            'search-submit',
            searchSubmitEl,
            sidePanel.getElement()?.querySelector('.side-panel__bottom .actions__block[data-content="search"]') as HTMLElement
          )

          if (filtersResetEl && filtersSubmitEl) {
            ElementMover.move(
              'filters-reset',
              filtersResetEl,
              sidePanel.getElement()?.querySelector('.side-panel__bottom .actions__block[data-content="filters"]') as HTMLElement
            )
            ElementMover.move(
              'filters-submit',
              filtersSubmitEl,
              sidePanel.getElement()?.querySelector('.side-panel__bottom .actions__block[data-content="filters"]') as HTMLElement
            )
          }
        },
        onClose: function () {
          if (activeTabEl) {
            const event = activeTabEl.getAttribute('data-target') === 'search' ? 'search' : 'filter'
            document.body.dispatchEvent(new CustomEvent(`search-page.${event}.modal.closed`))

            activeTabEl.classList.remove('tab__item--selected')
            activeContentEl?.classList.remove('visible')
            activeBottomEl?.classList.remove('visible')
            activeTabEl = null
          }

          self.activeSidePanel = null

          // reverse order of moved elements so we don't have missing nodes when repositionning elements
          if (filtersResetEl && filtersSubmitEl) {
            ElementMover.restore('filters-submit')
            ElementMover.restore('filters-reset')
          }

          ElementMover.restore('search-submit')

          if (filtersEl) {
            filtersEl.dispatchEvent(new CustomEvent('filter.modal.closed'))
            ElementMover.restore('filters')
          }

          ElementMover.restore('search')
        },
      })

      ElementPropertiesManager.addProperty(sidePanelEl, 'sidePanel', sidePanel)
    }

    sidePanel = ElementPropertiesManager.getProperty<SidePanel>(sidePanelEl, 'sidePanel')

    let activeTabEl = sidePanel?.getElement()?.querySelector('.tab__item--selected')
    let activeContentEl = sidePanel?.getElement()?.querySelector('.content__block.visible')
    let activeBottomEl = sidePanel?.getElement()?.querySelector('.actions__block.visible')

    sidePanel
      ?.getElement()
      ?.querySelectorAll('.tab__item')
      .forEach(function (el) {
        el.addEventListener('click', function (this: HTMLElement) {
          if (activeTabEl) {
            activeTabEl.classList.remove('tab__item--selected')
            activeContentEl?.classList.remove('visible')
            activeBottomEl?.classList.remove('visible')
          }

          const target = this.getAttribute('data-target') as string

          if (!activeTabEl || activeTabEl.getAttribute('data-target') !== target) {
            const event = target === 'search' ? 'search' : 'filter'
            document.body.dispatchEvent(new CustomEvent(`search-page.${event}.modal.opened`))
          }

          this.classList.add('tab__item--selected')

          // eslint-disable-next-line @typescript-eslint/no-this-alias
          activeTabEl = this
          activeContentEl = sidePanel?.getElement()?.querySelector(`.content__block[data-content="${target}"]`)
          activeBottomEl = sidePanel?.getElement()?.querySelector(`.actions__block[data-content="${target}"]`)

          activeTabEl.classList.add('tab__item--selected')
          activeContentEl?.classList.add('visible')
          activeBottomEl?.classList.add('visible')
        })
      })

    if (filtersSubmitEl) {
      filtersSubmitEl.addEventListener('click', function () {
        sidePanel?.close()
      })
    }
  },
} as SearchSidePanel
