// @codingStandardsIgnoreFile
'use strict'

import * as coreCommon from 'assets/core/js/common'
import SliderManager from 'assets/core/js/module/sliderManager'
import SearchMap from 'assets/core/js/module/searchMap'

import mapLoader from 'assets/themes/vacances-campings/js/module/mapLoader'

interface Map {
  isMapAvailable: boolean
  init: () => void
  map?: SearchMap
  initMap: (containerSelector: string) => void
  createMapSlides: (nodes: HTMLElement[], startIndex: number) => HTMLElement[]
}

export default {
  isMapAvailable: false,

  init: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    self.isMapAvailable = false

    document.addEventListener('click', function (e) {
      const target = e.target as HTMLElement

      if (target && target.classList.contains('toggle-map-link')) {
        e.preventDefault()
        const markerId = target.getAttribute('data-id') as string

        if (!self.map) {
          document.getElementById('map-container')?.addEventListener('map-initialized', function () {
            self.map?.openMarker(markerId)
          })
        } else {
          self.map?.openMarker(markerId)
        }

        document.getElementById('toggle-map')?.click()
      }
    })

    document.querySelectorAll('.toggle-map__button').forEach(function (el) {
      el.addEventListener('click', function () {
        const mapVisible = document.body.classList.toggle('map--visible')

        mapLoader()
          .then(() => {
            if (self.isMapAvailable === false) {
              self.initMap('#map-container')

              self.isMapAvailable = true
            }

            if (SliderManager.getSlider('map-products-slider')) {
              SliderManager.getSlider('map-products-slider')?.update()
              SliderManager.getSlider('map-products-slider')?.slideTo(SliderManager.getSlider('map-products-slider')?.activeIndex as number, 0, false)
            }

            const resultsMap = document.getElementById('results-map')

            if (mapVisible) {
              resultsMap?.dispatchEvent(new CustomEvent('search.map.modal.opened'))
            } else {
              resultsMap?.dispatchEvent(new CustomEvent('search.map.modal.closed'))
            }
          })
          .catch(() => true)
      })
    })
  },

  createMapSlides: function (nodes, startIndex) {
    const slides: HTMLElement[] = []
    const slideTmpl = (document.getElementById('map-slide-tmpl') as HTMLScriptElement).innerHTML
    let index = startIndex || 0

    nodes.forEach(function (node) {
      if (node.getAttribute('data-latitude') && node.getAttribute('data-longitude')) {
        const div = document.createElement('div')
        div.innerHTML = slideTmpl.trim()
        const slide = div.firstChild as HTMLElement

        const originalImg = node.querySelector('.result__image .dca-images-slider img')
        const bestOffer = node.querySelector('.availability__best-offer')

        if (bestOffer) {
          slide.setAttribute('data-index', index.toString())
          slide.setAttribute('data-id', node.getAttribute('data-id') as string)
          slide.setAttribute('data-url', node.querySelector('.result__name')?.getAttribute('href') as string)
          slide.querySelector('.slide-content')?.setAttribute('data-id', node.getAttribute('data-id') as string)

          if (originalImg) {
            const imageSrc = originalImg.getAttribute('data-src') ? originalImg.getAttribute('data-src') : originalImg.getAttribute('src')

            ;(slide.querySelector('.slide-image') as HTMLElement).innerHTML =
              '<img class="lazy" data-src="' + imageSrc + '" alt="' + originalImg.getAttribute('alt') + '" />'
          }

          const slideBestOffer = slide.querySelector('.product__best-offer')

          if (slideBestOffer) {
            slideBestOffer.innerHTML = bestOffer.innerHTML
            slideBestOffer.removeChild(slideBestOffer.querySelector('.best-offer__date') as HTMLElement)
            slideBestOffer.querySelector('.best-offer__price')?.removeChild(slideBestOffer.querySelector('.dca-button') as HTMLElement)
            const additionalPaymentInfo = slideBestOffer.querySelector('.additional-payment-info')
            if (additionalPaymentInfo) {
              additionalPaymentInfo.remove()
            }

            const elementsToRemove: HTMLElement[] = [slideBestOffer.querySelector('.dca-payment-facilities') as HTMLElement]

            elementsToRemove
              .filter((el) => el !== null)
              .forEach((elToRemove) => {
                ;(elToRemove.parentNode as HTMLElement).removeChild(elToRemove)
              })
          }

          ;(slide.querySelector('.slide-details .name') as HTMLElement).innerHTML = node.querySelector('.product__name')?.innerHTML as string

          slides.push(slide)

          index = index + 1
        }
      }
    })

    return slides
  },

  initMap: function (containerSelector) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const sliderWrapper = document.querySelector('#map-products-slider .swiper-wrapper')
    const slides = self.createMapSlides(Array.from(document.querySelectorAll<HTMLElement>('#results-list .result')), 0)

    slides.forEach(function (slide: HTMLElement) {
      sliderWrapper?.appendChild(slide)
    })

    SliderManager.initSlider(
      '#map-products-slider',
      {
        navigation: {
          nextEl: '.swiper-pictures-button-next',
          prevEl: '.swiper-pictures-button-prev',
        },
        grabCursor: true,
        spaceBetween: 16,
        speed: 1000,
        slidesPerView: 'auto',
        on: {
          init: function () {
            coreCommon.initLazyLoading('#results-map .lazy')
          },
          slideChangeTransitionStart: function (this: { slides: Record<string, HTMLElement>; activeIndex: number }) {
            if (self.map) {
              self.map.openMarker(this.slides[this.activeIndex]?.getAttribute('data-id') as string)
            }
          },
          click: function (this: { slides: Record<string, HTMLElement>; activeIndex: number }) {
            const url = (window.location.href = this.slides[this.activeIndex]?.getAttribute('data-url') as string)

            if (url) {
              window.location.href = url
            }
          },
        },
      },
      false
    )

    const jsonData = window.mapResults ?? []
    const markers = jsonData
      .filter((data) => data.coordinates.length > 0)
      .map((data) => ({
        id: data.id.toString(),
        overlayText: data.bestOffer && data.bestOffer.price ? data.bestOffer.price : undefined,
        latitude: data.coordinates[0],
        longitude: data.coordinates[1],
      }))

    self.map = new SearchMap(
      containerSelector,
      {
        markerProperties: {
          showIcon: () => true,
          onClick: function (markerId) {
            const slideEl = document.querySelector('.swiper-slide[data-id="' + markerId + '"]:not(.swiper-slide-duplicate)')
            setTimeout(function () {
              SliderManager.getSlider('map-products-slider')?.slideTo(parseInt(slideEl?.getAttribute('data-index') as string, 10))
            }, 150)

            return false
          },
        },
      },
      markers
    )
  },
} as Map
