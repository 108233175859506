'use strict'

import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import SearchFiltersRecap from 'assets/themes/vacances-campings/js/module/searchFiltersRecap'
import sidePanels from 'assets/themes/vacances-campings/js/page/search/sidePanels'

import type SidePanel from '@campings-group/design-system/src/design/objects/sidePanel/twig/assets'

export default function (): void {
  sidePanels.init()

  SearchFiltersRecap(document.getElementById('filter-offers') as HTMLElement, '#filters-sort input[name^="filter_offers"]', null, function (el) {
    if (!el) {
      return
    }

    const sidePanelEl = document.getElementById('search-filters-side-panel')

    if (!sidePanelEl) {
      return
    }

    const sidePanel = ElementPropertiesManager.getProperty<SidePanel>(sidePanelEl, 'sidePanel')

    if (!sidePanel) {
      return
    }

    sidePanel.open()

    let target: undefined | null | HTMLElement = null

    if (el.getAttribute('data-type') === 'search') {
      target = sidePanel.getElement().querySelector<HTMLElement>('.tab__item[data-target="search"]')
    }

    if (el.getAttribute('data-type') === 'filters') {
      target = sidePanel.getElement().querySelector<HTMLElement>('.tab__item[data-target="filters"]')
    }

    // fallback: try to find the first tab element
    if (!target) {
      target = sidePanel.getElement().querySelector<HTMLElement>('.tab__item:first-child')
    }

    if (target) {
      target.click()
    }
  })
}
