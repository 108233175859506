'use strict'

import FavoriteButton from 'assets/core/js/module/favorite/button'

class Button {
  element: HTMLElement
  namespace: string
  favorite: string

  constructor(element: HTMLElement, namespace: string, favorite: string) {
    this.element = element
    this.namespace = namespace
    this.favorite = favorite
  }

  initialize(): void {
    let data = null
    const rawData = this.element.getAttribute('data-favorite-datalayer-data')
    if (rawData !== null) {
      try {
        data = JSON.parse(rawData)
      } catch (error) {}
    }

    const button = new FavoriteButton(
      this.namespace,
      this.favorite,
      (registered: boolean) => {
        this.update(registered)
      },
      {
        addedSnackbar: document.getElementById('favorite_snackbar_added'),
        addFailedSnackbar: document.getElementById('favorite_snackbar_add_failed'),
        removedSnackbar: document.getElementById('favorite_snackbar_removed'),
        dataLayer: {
          /**
           * We could retrieve the value from a data-* attribute, or in the
           * page_view event.
           */
          pageCategory: 'search results',
          data,
        },
      }
    )

    this.update(button.initialize())

    this.element.addEventListener('click', () => {
      if (this.element.getAttribute('data-favorite-registered') === null) {
        button.add()
      } else {
        button.remove()
      }
    })
  }

  private update(registered: boolean): void {
    if (registered) {
      this.element.setAttribute('data-favorite-registered', '')
    } else {
      this.element.removeAttribute('data-favorite-registered')
    }
  }
}

/**
 * Setup the add/remove to favorite button, adding a data-favorite-registered
 * attribute when the product is already in the favorite, and handling click
 * events to update favorites.
 *
 * It should be called only after the DOM content is loaded.
 */
export default (): void => {
  const elements = document.querySelectorAll<HTMLElement>('.product-favorite')

  for (const element of elements) {
    if (element !== null && element.hasAttribute('data-favorite-namespace') && element.hasAttribute('data-favorite-product')) {
      const namespace = element.getAttribute('data-favorite-namespace') as string
      const product = element.getAttribute('data-favorite-product') as string
      const button = new Button(element, namespace, product)
      button.initialize()
    }
  }
}
