'use strict'

import * as coreCommon from 'assets/core/js/common'
import mapboxgl, { Control, LngLatBoundsLike, MapboxOptions } from 'mapbox-gl'

export interface MapConfig {
  onLoad?(): void
  onClose?: () => void
  closeButton?: boolean
}

declare global {
  interface Window {
    mapboxgl: {
      accessToken: string
    }
  }
}

export interface MapMarker {
  id: string
  overlayText?: string
  latitude: number
  longitude: number
}

export default class Map {
  element!: Element
  isInitialized = false
  markers!: mapboxgl.Marker[]
  config!: MapConfig
  mapLatitude!: string | null
  mapLongitude!: string | null
  mapBoundariesBox!: number[] | null
  map!: mapboxgl.Map
  bounds!: LngLatBoundsLike

  constructor(element: string | HTMLElement, config: MapConfig, markers: MapMarker[]) {
    let el: Element | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element)
    }

    if (!el || typeof el === 'string') {
      return
    }

    this.element = el
    this.markers = []
    this.config = config ?? {}

    const mapboxScript = document.getElementById('mapbox-js')

    if (!mapboxScript || (mapboxScript && !mapboxScript.getAttribute('data-token'))) {
      return
    }

    if (!window.mapboxgl || !this.element) {
      return
    }

    window.mapboxgl.accessToken = mapboxScript.getAttribute('data-token') as string

    this.initMap(markers)
  }

  initMap(markers: MapMarker[]): void {
    let mapStyle

    this.mapLatitude = this.element.getAttribute('data-latitude')
    this.mapLongitude = this.element.getAttribute('data-longitude')

    switch (this.element.getAttribute('data-maptype')) {
      case 'SATELLITE':
        mapStyle = 'mapbox://styles/mapbox/satellite-v9'
        break
      case 'ROADMAP':
      default:
        mapStyle = 'mapbox://styles/mapbox/outdoors-v11'
    }

    const mapConfig: MapboxOptions = {
      container: this.element as HTMLElement,
      zoom: Number(this.element.getAttribute('data-zoom')),
      style: mapStyle,
      center: [0, 0],
    }

    const mapBoundariesBox = this.element.hasAttribute('data-map-boundaries')
      ? JSON.parse<[number[], number[]]>(this.element.getAttribute('data-map-boundaries') as string)
      : null

    if (mapBoundariesBox && mapBoundariesBox.length === 2 && mapBoundariesBox[0]?.length === 2 && mapBoundariesBox[1]?.length === 2) {
      // @ts-ignore
      mapConfig.bounds = [...mapBoundariesBox[0], ...mapBoundariesBox[1]]
      this.mapBoundariesBox = [...mapBoundariesBox[0], ...mapBoundariesBox[1]]
    }

    if (this.mapLatitude && this.mapLongitude) {
      mapConfig.center = [parseFloat(this.mapLongitude), parseFloat(this.mapLatitude)]
    }

    const CloseControl = function (this: Control, callback: MapConfig['onClose']): Control {
      let container: HTMLElement | undefined

      this.onAdd = function () {
        container = document.createElement('div')
        container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group'

        const button = document.createElement('button')
        button.className = 'mapboxgl-ctrl-close'
        button.type = 'button'
        button.innerHTML = 'X'

        if (callback) {
          button.addEventListener('click', callback)
        }

        container.appendChild(button)
        return container
      }

      this.onRemove = function () {
        container?.parentNode?.removeChild(container)
      }

      return this
    }

    this.map = new window.mapboxgl.Map(mapConfig)

    if (this.config && this.config.closeButton && this.config.onClose) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.map.addControl(new CloseControl(this.config.onClose))
    }

    this.map.addControl(new window.mapboxgl.NavigationControl())
    setTimeout(() => this.resize(), 0)

    this.map.on('load', () => {
      this.isInitialized = true
      this.map.setLayoutProperty('country-label', 'text-field', ['get', `name${this.element.getAttribute('data-language') as string}`])
      this.element.dispatchEvent(new CustomEvent('map-initialized'))

      if (this.config.onLoad) {
        this.config.onLoad()
      }
    })

    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.map.resize()
        this.fitBounds()
      }, 250)
    })

    if (markers) {
      this.setMarkers(markers)
    }
  }

  resize(): mapboxgl.Map {
    return this.map.resize()
  }

  setMarkers(markers: MapMarker[]): Map {
    this.clearMarkers()

    markers.forEach((marker) => {
      this.setMarker(marker)
    })

    if (!this.mapLatitude && !this.mapLongitude && this.markers.length > 0 && !this.mapBoundariesBox) {
      this.fitBounds()
    }

    return this
  }

  clearMarkers(): Map {
    for (let i = 0, j = this.markers.length; i < j; i++) {
      this.markers[i]?.remove()
    }

    this.markers = []

    return this
  }

  setMarker(coordinate: MapMarker): mapboxgl.Marker | boolean {
    const markerTemplate = `<div class="marker-wrapper">
      <div class="marker-icon"></div>
    </div>`

    if (coordinate.latitude && coordinate.longitude) {
      const markerEl = coreCommon.createNodeFromHTML(markerTemplate)

      const marker = new window.mapboxgl.Marker(markerEl, {
        anchor: 'bottom',
      })
        .setLngLat([coordinate.longitude, coordinate.latitude])
        .addTo(this.map)

      this.markers.push(marker)

      return marker
    }

    return false
  }

  fitBounds(): Map {
    this.bounds = new window.mapboxgl.LngLatBounds()

    for (let i = 0, j = this.markers.length; i < j; i++) {
      const lnglat = this.markers[i]?.getLngLat()

      if (lnglat) {
        this.bounds.extend(lnglat)
      }
    }

    this.map.setCenter(this.bounds.getCenter())
    this.map.fitBounds(this.bounds, {
      duration: 0,
      padding: 100,
    })

    return this
  }

  getMap(): mapboxgl.Map {
    return this.map
  }
}
