'use strict'

import { createXhr, createNodeFromHTML } from 'assets/core/js/common'
import mapboxgl, { LngLatBoundsLike, Marker, Offset } from 'mapbox-gl'
import Map, { MapMarker, MapConfig } from './map'

export const MAP_MODE = {
  DEFAULT: 'default',
  QUERY: 'query',
} as const

type MapModeKeys = keyof typeof MAP_MODE
type MapModeValuesTypes = (typeof MAP_MODE)[MapModeKeys]

type SearchMarker = Marker & {
  markerId?: string
}

export interface QueryResponse {
  markers: {
    id: string
    text: string
    coordinates: { lat: number; lon: number }
  }[]
  tiles: {
    [key: string]: string
  }
}

export interface SearchMapConfig extends MapConfig {
  mode?: MapModeValuesTypes
  queryUrl?: string | null
  onMove?(coordinates: number[][]): void
  onQuery?(response: QueryResponse): void
  markerProperties: {
    offset?: Offset
    getOverlayText?(marker: MapMarker): string
    onClick?(markerId: string): void
    getContent?(markerId: string): HTMLElement | undefined
    showIcon?(marker: MapMarker): boolean
  }
}

export default class SearchMap extends Map {
  activeMarker!: undefined | SearchMarker
  config!: SearchMapConfig
  queryXhrRequest!: XMLHttpRequest | null

  constructor(element: string | HTMLElement, config: SearchMapConfig, markers: MapMarker[]) {
    super(element, config, markers)

    if (!this.config) {
      return
    }

    this.config.mode = config && config.mode ? config.mode : MAP_MODE.DEFAULT

    if (this.config.mode === MAP_MODE.QUERY) {
      if (!this.element.hasAttribute('data-query-url')) {
        return
      }

      this.config.queryUrl = this.element.getAttribute('data-query-url')

      this.query(this.map.getBounds().toArray())
    }

    this.activeMarker = undefined
  }

  initMap(markers: MapMarker[]): void {
    super.initMap(markers)

    // don't use the popup event as it will remove the active marker class
    this.map.on('click', (e: MouseEvent) => {
      // @ts-ignore
      const target = (e.originalEvent as MouseEvent).target as HTMLElement

      if (
        !target.classList.contains('mapboxgl-marker') &&
        !target.classList.contains('marker-icon') &&
        !target.classList.contains('marker-overlay')
      ) {
        this.removeActiveMarker()
      }
    })

    // "moveend" is also triggered by "zoomend"
    this.map.on('moveend', (e) => {
      const coordinates = this.map.getBounds().toArray()
      // this event is also triggered when the map is loading but without the "originalEvent" property
      if (this.config.onMove && e.originalEvent) {
        if (this.config.mode === MAP_MODE.QUERY) {
          this.query(coordinates)
        }

        this.config.onMove(coordinates)
      }
    })
  }

  setMapMode(mode: SearchMapConfig['mode']): SearchMap {
    this.config.mode = mode

    return this
  }

  query(coordinates: LngLatBoundsLike | number[][]): void {
    let separator = '?'
    if (this.config.queryUrl?.includes('?')) {
      separator = '&'
    }
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const url = `${this.config.queryUrl}${separator}tr=${coordinates[1].join(',')}&bl=${coordinates[0].join(',')}`

    if (this.queryXhrRequest && this.queryXhrRequest.readyState < XMLHttpRequest.DONE) {
      this.queryXhrRequest.abort()
    }

    this.queryXhrRequest = createXhr('GET', url)
    this.queryXhrRequest.setRequestHeader('Content-type', 'application/json')
    this.queryXhrRequest.onreadystatechange = () => {
      if (this.queryXhrRequest && this.queryXhrRequest.readyState === XMLHttpRequest.DONE && this.queryXhrRequest.status === 200) {
        const response = JSON.parse<QueryResponse>(this.queryXhrRequest.response as string)

        if (this.config.onQuery) {
          this.config.onQuery(response)
        }

        const markers: MapMarker[] = []

        Object.values(response.markers).forEach((marker) => {
          markers.push({
            id: marker.id.toString(),
            overlayText: marker.text,
            latitude: marker.coordinates.lat,
            longitude: marker.coordinates.lon,
          })
        })

        if (!this.isInitialized) {
          const loadCallback = (): void => {
            this.setMarkers(markers)
            this.map.off('load', loadCallback)
          }

          this.map.on('load', loadCallback)
        }

        this.setMarkers(markers)

        this.element.dispatchEvent(new CustomEvent('map-query-done'))
      }
    }

    this.queryXhrRequest.send()
  }

  openMarker(markerId: string): SearchMap {
    if (this.activeMarker && this.activeMarker?.getElement().id.replace('marker-', '') === markerId) {
      return this
    }

    if (this.activeMarker) {
      this.removeActiveMarker()
    }

    this.setActiveMarker(markerId)

    if (this.activeMarker?.getPopup() && !this.activeMarker?.getPopup().isOpen()) {
      this.activeMarker?.togglePopup()
    }

    if (this.config.markerProperties.onClick) {
      this.config.markerProperties.onClick(markerId)
    }

    return this
  }

  setActiveMarker(markerId: string): SearchMap {
    const selectedMarker = this.markers.filter((marker) => {
      // @ts-ignore
      return marker.markerId === markerId
    })[0]

    this.activeMarker = selectedMarker

    this.activeMarker?.getElement().classList.add('marker--active')

    return this
  }

  removeActiveMarker(): SearchMap {
    if (this.activeMarker) {
      this.activeMarker.getElement().classList.remove('marker--active')
      this.activeMarker.getElement().classList.remove('marker--hover')

      if (this.activeMarker.getPopup() && this.activeMarker.getPopup().isOpen()) {
        this.activeMarker.togglePopup()
      }

      this.activeMarker = undefined
    }

    return this
  }

  clearMarkers(): SearchMap {
    let filteredMarkers: SearchMarker[] = this.markers

    if (this.activeMarker) {
      filteredMarkers = filteredMarkers.filter((marker) => marker.markerId !== this.activeMarker?.markerId)
    }

    for (let i = 0, j = filteredMarkers.length; i < j; i++) {
      filteredMarkers[i]?.remove()
    }

    if (this.activeMarker) {
      this.markers = [this.activeMarker]
    } else {
      this.markers = []
    }

    return this
  }

  setMarker(marker: MapMarker): mapboxgl.Marker | boolean {
    const markerId = marker.id.toString()

    let overlayText = marker.overlayText ?? null

    if (this.config.markerProperties.getOverlayText) {
      overlayText = this.config.markerProperties.getOverlayText(marker)
    }

    const overlay = overlayText ? `<div class="marker-overlay">${overlayText}</div>` : ''

    const markerTemplate = `<div class="marker-wrapper">
      ${this.config.markerProperties.showIcon && this.config.markerProperties.showIcon(marker) ? '<div class="marker-icon"></div>' : ''}
      ${overlay}
    </div>`

    if (marker.latitude && marker.longitude) {
      const markerEl = createNodeFromHTML(markerTemplate)
      markerEl.id = 'marker-' + markerId
      markerEl.addEventListener('mouseover', function () {
        markerEl.classList.add('marker--hover')
      })
      markerEl.addEventListener('mouseout', function () {
        markerEl.classList.remove('marker--hover')
      })
      markerEl.addEventListener('click', () => {
        this.removeActiveMarker()
        this.setActiveMarker(markerId)

        if (this.config.markerProperties.onClick) {
          this.config.markerProperties.onClick(markerId)
        }
      })

      const markerInstance: SearchMarker = new window.mapboxgl.Marker(markerEl, {
        anchor: 'bottom',
      }).setLngLat([marker.longitude, marker.latitude])

      if (this.config.markerProperties.getContent) {
        const popup: mapboxgl.Popup & { _container?: HTMLElement } = new window.mapboxgl.Popup({
          className: 'marker-popup',
          offset: this.config.markerProperties.offset ?? {
            bottom: [0, -50],
          },
        })
        const contentNode = this.config.markerProperties.getContent(markerId)
        contentNode && popup.setDOMContent(contentNode)
        popup.setMaxWidth('none')
        popup.on('open', () => {
          const pan: [number, number] = [0, 0]
          const point = this.map.project(popup.getLngLat())
          const popupSize = {
            width: popup._container?.clientWidth,
            height: popup._container?.clientHeight,
          }
          const popupRect = popup._container?.getBoundingClientRect()
          const mapRect = this.map.getContainer().getBoundingClientRect()

          if (!popupRect) {
            return
          }

          // center point horizontally to make the popup visible, if needed
          const popupRelativeLeft = popupRect.x - mapRect.x
          const popupClippedHorizontally = popupRelativeLeft < 0 || mapRect.width - popupRelativeLeft - (popupSize.width ?? 0) < 0
          if (popupClippedHorizontally) {
            pan[0] = point.x - mapRect.width / 2
          }

          // move point to the top to make the popup visible, if needed
          const popupRelativeTop = popupRect.y - mapRect.y
          const popupClippedVertically = popupRelativeTop < 0 || mapRect.height - popupRelativeTop - (popupSize.height ?? 0) < 0
          if (popupClippedVertically) {
            pan[1] = point.y - (mapRect.height - popupRect.height) / 2
          }

          if (pan[0] === 0 && pan[1] === 0) {
            return
          }

          this.map.panBy(pan)
        })

        markerInstance.setPopup(popup)
      }

      markerInstance.addTo(this.map)

      markerInstance.markerId = markerId.toString()

      this.markers.push(markerInstance)

      return markerInstance
    }

    return false
  }
}
