'use strict'

import SelectManager from 'assets/core/js/module/selectManager'

export default function (): void {
  const form = document.getElementById('filter-offers') as HTMLFormElement

  if (form) {
    const submitEl = form.querySelector('[type="submit"]') as HTMLButtonElement

    document.querySelectorAll<HTMLInputElement>('input[name="filter_offers[sortBy]"]').forEach((el) => {
      el.addEventListener('change', function () {
        submitEl.click()
      })
    })

    if (document.getElementById('sort-select') !== null) {
      const sortingSelect = SelectManager.getSelect('sort-select')

      sortingSelect.passedElement.element.addEventListener('change', function () {
        const checkboxEl = document.querySelector<HTMLInputElement>(
          `input[name="filter_offers[sortBy]"][value="${sortingSelect.passedElement.element.value}"]`
        )

        if (!checkboxEl) {
          return
        }

        checkboxEl.checked = true

        if ('createEvent' in document) {
          const evt = document.createEvent('HTMLEvents')
          evt.initEvent('change', false, true)
          checkboxEl?.dispatchEvent(evt)
        } else {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          checkboxEl?.fireEvent('onchange')
        }
      })
    }
  }
}
