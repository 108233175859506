'use strict'

import common from 'assets/themes/vacances-campings/js/page/common/index'

export default function (): void {
  const preventOpenClasses = [
    'toggle-map-link',
    'localisation',
    'review__link',
    'review__title',
    'review__value',
    'review__max',
    'best-offer__payment-facilities',
  ]

  document.querySelectorAll('.result__best-offer').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      if (preventOpenClasses.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const target = this.querySelector('.dca-button') as HTMLLinkElement
        let link

        if (target) {
          link = (target.getAttribute('data-href') as string) || target.href
          // The link can be obfuscated (see assets/core/js/module/seoLink.ts).
          if (target.getAttribute('data-seo-link-href')) {
            link = atob(target.getAttribute('data-seo-link-href') as string)
          }
        } else {
          link = this.closest('.result')?.querySelector<HTMLLinkElement>('.result__name')?.href as string
        }
        openLink(link, target)
      }
    })
  })

  document.querySelectorAll('.results__list .result__details').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      if (!common.isMobile() && preventOpenClasses.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const link = this.querySelector<HTMLLinkElement>('.result__name')?.href as string
        openLink(link, target)
      }
    })
  })

  document.querySelectorAll('.results__list .result__image img').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      e.preventDefault()
      e.stopPropagation()

      const link = this.closest('.result')?.querySelector<HTMLLinkElement>('.result__name')?.href
      openLink(link, target)
    })
  })

  document.querySelectorAll('.product__reviews').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      if (preventOpenClasses.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const link = this.querySelector<HTMLLinkElement>('.review__link')
        let href = null
        if (!common.isMobile() && link) {
          href = link.href
        } else if (link) {
          href = link.href.split('#')[0]
        }
        openLink(href, target)
      }
    })
  })
}

function openLink(url: string | null | undefined, el: HTMLElement): void {
  if (url) {
    if (!common.isMobile()) {
      window.open(url)
    } else {
      document.location.href = url
    }

    const event = new CustomEvent('productPageLink.opened', { detail: { productId: el.closest('.result')?.getAttribute('data-id') } })
    window.dispatchEvent(event)
  }
}
