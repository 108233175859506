'use strict'

import submitFilterForm from './submitFilterForm'

export default function (): void {
  document.querySelectorAll<HTMLInputElement>('.filter__block input[type="checkbox"]').forEach((el) => {
    el.addEventListener('change', submitFilterForm)

    const topFilterTargetId = el.getAttribute('data-top-filter-target') as string

    const topFilter = document.querySelector<HTMLInputElement>(
      `.filter__block[data-filter="top"] input[type="checkbox"][data-top-filter-target="${el.id}"]`
    )
    const topFilterTarget = <HTMLInputElement | null>document.getElementById(topFilterTargetId)

    if (topFilterTarget) {
      el.addEventListener('click', () => {
        topFilterTarget.checked = el.checked
      })
    }

    if (topFilter) {
      el.addEventListener('click', () => {
        topFilter.checked = el.checked
      })
    }
  })

  document.querySelectorAll<HTMLElement>('.filter__block.block--collapsed .properties__toggle').forEach(function (el) {
    el.addEventListener('click', function (e) {
      e.preventDefault()

      const currentText = el.innerText
      el.innerText = el.getAttribute('data-alternate-text') ?? ''
      el.setAttribute('data-alternate-text', currentText)

      if (el.parentNode) {
        ;(<HTMLElement>el.parentNode).classList.toggle('block--view-all')
      }
    })
  })
}
