'use strict'

import { loadLink, loadScript } from 'assets/core/js/common'

function getMapbox(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (window.mapboxgl) {
      resolve()
    } else {
      reject(new Error('Mapbox global object is not available.'))
    }
  })
}

const mapboxVersion = '1.8.1'

export default function (): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(function (resolve, reject): Promise<void> {
    return getMapbox()
      .then(resolve)
      .catch(() =>
        Promise.all([
          loadLink(`//api.tiles.mapbox.com/mapbox-gl-js/v${mapboxVersion}/mapbox-gl.css`, 'mapbox-css'),
          loadScript(`//api.tiles.mapbox.com/mapbox-gl-js/v${mapboxVersion}/mapbox-gl.js`, 'mapbox-js', getMapbox),
        ])
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject(new Error('Could not load mapbox.'))
          })
      )
  })
}
